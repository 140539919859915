import { motion } from "framer-motion";

export default function SessionBalance({
    requestedSessions,
    isLiveSessionsMultiTeachers,
    printUnit,
}) {
    return (
        <div
            className="relative bg-violet-50 dark:bg-violet-950/30 smooth text-violet-900 dark:text-violet-100 
                        border-violet-500 rounded-lg border p-6 flex flex-col items-center shadow-xl"
        >
            <motion.div
                className="absolute top-1/2 left-0 h-12 w-3 bg-yellow-400 rounded-full"
                animate={{ y: [0, -10, 10, 0] }}
                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
            />
            <motion.div
                className="absolute top-1/2 right-0 h-12 w-3 bg-yellow-400 rounded-full"
                animate={{ y: [0, 10, -10, 0] }}
                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
            />
            <h1 className="text-xl font-bold text-center text-violet-800 dark:text-violet-200 smooth">
                رصيدك الحالي المتبقي من الجلسات اللي ممكن تحجزها
            </h1>

            <div className="py-6 bg-violet-100 dark:bg-violet-900/50 smooth rounded-xl shadow-md w-fit mx-auto px-20 mt-4">
                <h2 className="font-bold text-lg text-center text-gray-900  dark:text-white ">
                    {isLiveSessionsMultiTeachers ? (
                        requestedSessions.user_available_sessions_count &&
                        Object.keys(requestedSessions.user_available_sessions_count).length > 0 ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                {Object.entries(
                                    requestedSessions.user_available_sessions_count
                                ).map(([teacherId, data]) => (
                                    <motion.div
                                        key={teacherId}
                                        className="relative bg-violet-50 smooth dark:bg-gray-700 smooth p-6 rounded-xl shadow-md border border-yellow-400 
                                                   flex flex-col items-center transition-all duration-300 transform hover:scale-105"
                                        whileHover={{ scale: 1.1 }}
                                    >
                                        <span className="text-base font-bold text-gray-800 dark:text-white">
                                            {data.teacher_name}
                                        </span>
                                        <span className="text-yellow-500 font-semibold text-base">
                                            {printUnit(data.live_sessions_allowed, "جلسة")}
                                        </span>
                                    </motion.div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-red-500 text-center font-semibold mt-2">
                                لا يوجد لديك رصيد متبقي لكل المعلمين!
                            </p>
                        )
                    ) : (
                        <p className="text-2xl font-bold text-violet-600 dark:text-white smooth">
                            {requestedSessions.user_available_sessions_count > 0
                                ? printUnit(requestedSessions.user_available_sessions_count, "جلسة")
                                : "لا يوجد لديك رصيد متبقي!"}
                        </p>
                    )}
                </h2>
            </div>
            <div
                className="py-4 text-center mt-4 bg-yellow-100 dark:bg-yellow-900/40 text-yellow-900 smooth dark:text-yellow-100 
                            p-4 rounded-md shadow-md border border-yellow-500 w-full"
            >
                <h2 className="text-lg font-bold">⚠️ تنبيه هام!</h2>
                <p className="mt-2">
                    خلي بالك انت بيكون ليك حق{" "}
                    <span className="text-rose-500 font-bold">
                        {isLiveSessionsMultiTeachers
                            ? "تحجز جلسة واحدة فقط لكل مدرس"
                            : "تحجز جلسة واحدة فقط "}{" "}
                        في الأسبوع
                    </span>{" "}
                    ولو حجزت جلسة ومجيتش هتتحسب عليك كغياب ومش هتقدر تعوضها.
                </p>
            </div>
        </div>
    );
}
