import React from "react";

import { ScrollingProvider } from "../../context/ScrollingContext";

import SectionHead from "../../components/ui/SectionHead";
import Container from "../../components/ui/Container";
import CourseCard from "../../components/ui/CourseCard";

// import year1 from "../../../assets/year-2.jpeg";
import year2 from "../../assets/year2.jpeg";
import year3 from "../../assets/year3.jpeg";
import year1 from "../../assets/year1.jpeg";
import year5 from "../../assets/year5.jpeg";
import year4 from "../../assets/year4.jpg";
import Waves from "../../components/ui/Waves";
import { motion } from "framer-motion";
const YearsSection = ({ title = false }) => {
    // const { token } = useContext(AuthContext);
    return (
        <div className="relative pb-20 overflow-hidden" id="courses">
            <ScrollingProvider>
                <SectionHead title={title ? title : "السنوات الدراسية"} />
            </ScrollingProvider>
            <section className="relative w-full min-h-screen flex flex-col md:flex-row items-center justify-center px-6 md:px-20 text-clr-text-primary">
                {/* Background Animated Circles */}
                <div className="absolute inset-0 pointer-events-none">
                    {[...Array(10)].map((_, i) => (
                        <motion.div
                            key={i}
                            className="absolute w-32 h-32 bg-gradient-to-br from-purple-500 to-indigo-500 opacity-20 rounded-full blur-3xl"
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{
                                duration: 2,
                                repeat: Infinity,
                                repeatType: "mirror",
                                delay: i * 0.5,
                            }}
                            style={{
                                top: `${Math.random() * 100}%`,
                                left: `${Math.random() * 100}%`,
                            }}
                        />
                    ))}
                </div>

                <Container className="flex-center-both flex-col">
                    <div
                        className={`g-teal-400 smooth clr-text-primary drk:bg-teal-800 bg-opacity-50 dark:bg-opacity-50 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10`}
                    >
                        <CourseCard
                            name="الصف الدراسي الأول"
                            description="جميع كورسات الصف الأول الثانوي"
                            isYear={1}
                            isStaticPicture={year1}
                        />
                        <CourseCard
                            name="الصف الدراسي الثاني"
                            description="جميع كورسات الصف الثاني الثانوي"
                            isYear={2}
                            isStaticPicture={year2}
                        />
                        <CourseCard
                            name="الصف الدراسي الثالث"
                            description="جميع كورسات الصف الثالث الثانوي"
                            isYear={3}
                            isStaticPicture={year3}
                        />
                        <CourseCard
                            name="الصف الدراسي الثالث الإعدادي"
                            description="جميع كورسات الصف الثالث الإعدادي"
                            isYear={5}
                            isStaticPicture={year5}
                        />
                        <CourseCard
                            name="كورسات عامة"
                            description="كورسات لجميع المراحل الدراسية"
                            isYear={4}
                            isStaticPicture={year4}
                        />
                    </div>
                </Container>
            </section>

            {/* <Waves className="fill-yellow-200" /> */}
            {/* <div className="py-16 bg-yellow-200"></div> */}
        </div>
    );
};

export default YearsSection;
