import React from "react";
import { useParams } from "react-router-dom";
import MainSection from "../sections/home/MainSection";
import ProfileSection from "../sections/home/ProfileSection";
import Wise1Section from "../sections/home/Wise1Section";
import Wise2Section from "../sections/home/Wise2Section";
import { ScrollingProvider } from "../context/ScrollingContext";
import { getYearPlaceHolder } from "../services/yearSevices";
import CoursesSection from "../sections/home/CoursesSection/CoursesSection";
import AboutSection from "../sections/home/AboutSection";
import HomeSectionLiveSessionSection from "../sections/home/HomeSectionLiveSessionSection";
import auth from "../services/authServices";

const Year = () => {
    const { id } = useParams();
    const token = auth.getToken();

    return (
        <>
            <ScrollingProvider>
                <MainSection title={getYearPlaceHolder(id)} />
                <AboutSection />
            </ScrollingProvider>

            {token && (
                <div className="py-10 bg-[#F43F5E]">
                    <HomeSectionLiveSessionSection />{" "}
                </div>
            )}
            <CoursesSection title={getYearPlaceHolder(id)} year={id} />
        </>
    );
};

export default Year;
