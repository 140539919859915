import React, { useEffect, useState } from "react";
import SessionReservationForm from "./SessionReservationForm";
import Heading from "../components/NewUi/heading/Heading";
import auth from "../services/authServices";
import http from "../services/httpServices";
import PreviousUserLiveSessions from "./PreviousUserLiveSessions";
import UpcomingLiveSessions from "./UpcomingLiveSessions";
import { printUnit } from "../utils/ar";
import LoadingIcon from "../components/ui/LoadingIcon";
import { isLiveSessionsMultiTeachers } from "../services/defaultSettings";
import { motion } from "framer-motion";
import SessionBalance from "../components/ui/SessionBalance";

const SessionReservation = () => {
    const [requestedSessions, setRequestedSessions] = useState({
        upcoming_live_sessions: [],
        previous_live_sessions: [],
        user_available_sessions_count: 0,
    });
    const [isLoading, setIsLoading] = useState(false);
    const getAttendantSessions = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        try {
            const { data: response } = await http.get(
                `/api/live_sessions/requested_sessions`,
                config
            );
            setRequestedSessions(response);
            setIsLoading(false);
        } catch ({ response }) {
            // console.log(error);
        }
    };
    useEffect(() => {
        getAttendantSessions();
    }, []);
    return (
        <div className=" h-full min-h-screen flex-col space-y-20 flex-center-both py-20 px-4 md:px-10">
            {/* user_available_sessions_count */}

            {isLoading ? (
                <div className="flex-center-both flex-row space-x-3 space-x-reverse bg-third-container clr-text-primary border border-secondary-container smooth py-5 px-5 rounded-md">
                    <div className="font-w-bold font-h1 text-sky-500">
                        <LoadingIcon />
                    </div>
                    <div className="font-w-bold font-h3">جاري تحميل البيانات</div>
                </div>
            ) : requestedSessions.upcoming_live_sessions &&
              requestedSessions.upcoming_live_sessions.length > 0 ? (
                <>
                    <UpcomingLiveSessions
                        UpcomingLiveSessions={requestedSessions.upcoming_live_sessions}
                    />
                    <SessionBalance
                        requestedSessions={requestedSessions}
                        isLiveSessionsMultiTeachers={isLiveSessionsMultiTeachers}
                        printUnit={printUnit}
                    />
                    <PreviousUserLiveSessions
                        previousSessions={requestedSessions.previous_live_sessions}
                        getAttendantSessions={getAttendantSessions}
                    />
                </>
            ) : (
                <>
                    <div
                        className="relative flex flex-col items-center space-y-10 bg-gray-100 smooth dark:bg-gray-900 
                        border border-gray-300 dark:border-gray-700 rounded-3xl p-8 shadow-lg 
                        overflow-hidden"
                    >
                        <motion.div
                            className="relative flex items-center justify-center w-full"
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        >
                            <motion.div
                                className="absolute left-0 h-1 w-16 bg-blue-400 rounded-full"
                                animate={{ x: [0, 10, -10, 0] }}
                                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                            />
                            <motion.div
                                className="absolute right-0 h-1 w-16 bg-blue-400 rounded-full"
                                animate={{ x: [0, -10, 10, 0] }}
                                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                            />
                            <h2
                                className="text-violet-600 dark:text-violet-200 smooth text-4xl font-extrabold px-10 py-4 
                               bg-black bg-opacity-10 dark:bg-opacity-20 rounded-xl shadow-lg"
                            >
                                جاهز لجلسة سريعة؟ 🚀
                            </h2>
                        </motion.div>
                        <p className="text-gray-700 dark:text-gray-300 text-center smooth w-full md:w-1/2 text-xl font-medium leading-relaxed">
                            عندك استفسار وعايز إجابة فورية؟ 💡 دلوقتي تقدر تحجز **جلسة فردية مع
                            مُدرّس مُساعد** في الوقت اللي يناسبك. فقط اختر **اليوم المناسب**، وشوف
                            المواعيد المتاحة وانضم بسهولة!
                        </p>

                        {/* Previous User Sessions */}
                        <PreviousUserLiveSessions
                            previousSessions={requestedSessions.previous_live_sessions}
                            getAttendantSessions={getAttendantSessions}
                        />
                    </div>
                    <SessionBalance
                        requestedSessions={requestedSessions}
                        isLiveSessionsMultiTeachers={isLiveSessionsMultiTeachers}
                        printUnit={printUnit}
                    />
                    {!isLiveSessionsMultiTeachers && (
                        <div className="flex flex-col space-y-8">
                            <SessionReservationForm
                                afterSuccess={getAttendantSessions}
                                userAvailableSessionsCount={
                                    requestedSessions.user_available_sessions_count
                                }
                            />
                        </div>
                    )}
                </>
            )}
            {isLiveSessionsMultiTeachers && (
                <div className="flex flex-col space-y-8">
                    <SessionReservationForm
                        afterSuccess={getAttendantSessions}
                        userAvailableSessionsCount={requestedSessions.user_available_sessions_count}
                    />
                </div>
            )}
        </div>
    );
};

export default SessionReservation;
