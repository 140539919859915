import React, { useEffect, useState } from "react";
import Button from "../components/ui/Button";
import StudentFeedbackIcon from "../assets/Updated-imagery/customer-review.png";
import LiveSessionUserAttendanceCard from "./LiveSessionUserAttendanceCard";

const PreviousUserLiveSessions = ({ previousSessions = [], getAttendantSessions = () => null }) => {
    const [isShowMore, setIsShowMore] = useState(false);
    const [ToShowRequestedSessions, setToShowRequestedSessions] = useState(false);

    useEffect(() => {
        if (isShowMore) {
            setToShowRequestedSessions(previousSessions);
        } else {
            setToShowRequestedSessions(previousSessions.slice(0, 3));
        }
    }, [previousSessions, isShowMore]);

    return (
        <div className="w-full flex-center-both flex-col space-y-10">
            {ToShowRequestedSessions && ToShowRequestedSessions.length > 0 ? (
                <>
                    <h1 className="font-h1 mx-auto clr-text-primary smooth">الجلسات المنتهية</h1>
                    <div>
                        <>
                            <div className="w-full flex-center-both flex-col space-y-10 p-5">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4 ">
                                    {ToShowRequestedSessions.map((session) => (
                                        <LiveSessionUserAttendanceCard
                                            session={session}
                                            handleAfterSuccess={getAttendantSessions}
                                            layout="detailed"
                                        />
                                    ))}
                                </div>
                            </div>
                        </>
                        {previousSessions.length > 3 && (
                            <div className="flex-center-both">
                                <Button
                                    onClick={() => setIsShowMore(!isShowMore)}
                                    className="bg-violet-600 text-white font-h3 font-w-bold px-5 py-3 rounded-md"
                                >
                                    {isShowMore ? "عرض أقل" : "عرض المزيد"}
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <div className="w-full opacity-40 flex-center-both  flex-col space-y-5  bg-slate-200 border border-black rounded-3xl py-5 px-20 dark:bg-slate-800 smooth ">
                    <img
                        src={StudentFeedbackIcon}
                        alt="StudentFeedbackIcon"
                        className="w-20 h-auto"
                    />
                    <span className="w-3/4 font-h2 text-center">
                        {" "}
                        لا توجد جلسات منتهية لغاية دلوقتي
                    </span>
                </div>
            )}
        </div>
    );
};

export default PreviousUserLiveSessions;
