import React, { useEffect, useState } from "react";
import auth from "../../services/authServices";
import http from "../../services/httpServices";
import UpcomingLiveSessions from "../../pages/UpcomingLiveSessions";
import Heading from "../../components/NewUi/heading/Heading";
import Button from "../../components/ui/Button";
import { motion } from "framer-motion";

const HomeSectionLiveSessionSection = () => {
    const [requestedSessions, setRequestedSessions] = useState({
        upcoming_live_sessions: [],
        previous_live_sessions: [],
    });
    const getAttendantSessions = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        try {
            const { data: response } = await http.get(
                `/api/live_sessions/requested_sessions`,
                config
            );
            setRequestedSessions(response);
        } catch ({ response }) {
            // console.log(error);
        }
    };
    useEffect(() => {
        getAttendantSessions();
    }, []);
    return (
        <div className="mt-20">
            {requestedSessions.upcoming_live_sessions &&
            requestedSessions.upcoming_live_sessions.length > 0 ? (
                <div className="flex-center-both">
                    <div className="flex-center-both flex-col w-fit space-y-10 relative p-10 bg-gradient-to-r from-pink-500 to-red-500 rounded-2xl shadow-xl">
                        <div className="relative flex items-center justify-center w-full">
                            <motion.div
                                className="absolute left-0 h-1 w-16 bg-violet-400 rounded-full"
                                animate={{ x: [0, 10, -10, 0] }}
                                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                            />
                            <motion.div
                                className="absolute right-0 h-1 w-16 bg-violet-400 rounded-full"
                                animate={{ x: [0, -10, 10, 0] }}
                                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                            />

                            <UpcomingLiveSessions
                                isHomeSection
                                UpcomingLiveSessions={requestedSessions.upcoming_live_sessions}
                            />
                        </div>
                        <div className="flex-center-both flex-col py-10 space-y-5">
                            <p className="text-center w-full md:w-1/2 text-gray-100 smooth font-h3 font-w-bold">
                                احجز دلوقتي معاد مع الجلسات المباشره في الميعاد اللي يناسبك و في
                                الموضوع اللي انت عاوزه !
                            </p>
                            <Button
                                element="Link"
                                to="/session_reservation"
                                color="violet"
                                className="w-2/3 text-center"
                            >
                                اذهب للحجز الآن !
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex-center-both">
                    <div className="flex-center-both flex-col w-fit space-y-10 relative p-10 bg-gradient-to-r from-pink-500 to-red-500 rounded-2xl shadow-xl">
                        <div className="relative flex items-center justify-center w-full">
                            <motion.div
                                className="absolute left-0 h-1 w-16 bg-violet-400 rounded-full"
                                animate={{ x: [0, 10, -10, 0] }}
                                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                            />
                            <motion.div
                                className="absolute right-0 h-1 w-16 bg-violet-400 rounded-full"
                                animate={{ x: [0, -10, 10, 0] }}
                                transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                            />

                            <h2 className="text-white text-4xl font-extrabold px-10 py-4 bg-black bg-opacity-20 rounded-xl shadow-lg">
                                الجلسات المباشرة
                            </h2>
                        </div>

                        {/* Description Text */}
                        <p className="text-white text-center w-full md:w-1/2 font-h3 font-w-bold">
                            احجز جلستك المباشرة مع FullMark في الوقت اللي يريحك والموضوع اللي يهمك،
                            عشان نقربك من اللغة، نقلل خوفك من مادة الإنجليزي، ونجاوب على كل أسئلتك!
                            🚀📚
                        </p>

                        {/* Button */}
                        <Button
                            element="Link"
                            to="/session_reservation"
                            color="yellow"
                            className="w-2/3 text-2xl text-center group  border-none py-3 rounded-lg shadow-md "
                        >
                            <span className="text-black font-w-bold group-hover:text-violet-900">
                                اذهب للحجز الآن!
                            </span>
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HomeSectionLiveSessionSection;
