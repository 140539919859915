/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdminContainer from "../../components/ui/AdminContainer";
import AdminForm from "../../components/ui/AdminForm";
import InputIcon from "../../components/form/elements/InputIcon";
import { years } from "../../services/yearSevices";
import {
    handleCrud,
    handleFormSubmit,
    handleInputChange as handleChange,
    renderInputFields,
} from "../../services/formServices";
import auth from "../../services/authServices";
import http from "../../services/httpServices";

import { submitTypes } from "../../services/adminServices";
import { isMultiYear } from "../../services/defaultSettings";

const initialState = {
    name: "",
    description: "",
    duration_in_minutes: 0,
    year: 3,
    admin_id: 0,
    session_day: "",
    maximum_attendance: 0,
    session_date: new Date().toISOString().split("T")[0], // Set today's date as default
    session_url: "",
    course_id: [],
    session_id: 0,
    submit_type: 0,
    choosen_admin_id: 0,
    teacher_id: 0,
    choosen_session_day: "",
};

const SessionEditing = () => {
    const [data, setData] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [inputFields, setInputFields] = useState([]);
    const [courses, setCourses] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [teachers, setTeachers] = useState([]);

    const getCourses = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(
            // `/api/years/${data.year}/courses/options`,
            `/api/teachers/${data.teacher_id}/years/${data.year}/courses/options`,
            config
        );
        setCourses(response);
    };
    const getSessions = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(`/api/live_sessions/options`, {
            params: {
                admin_id: data.choosen_admin_id,
                session_day: data.choosen_session_day,
            },
            ...config,
        });
        setSessions(response);
    };
    const getTeachers = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/admin/teachers/options`, config);
        setTeachers(response);
    };
    const getAdmins = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(`/api/admins`, config);

        let adminOptions = response.map((user) => {
            return { value: user.id, text: user.full_name + ` (@${user.email})` };
        });
        setAdmins(adminOptions);
    };

    const getSessionsInfo = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);

        const { data: response } = await http.get(`/api/live_sessions/${data.session_id}`, config);
        let result = {};
        Object.keys(response).forEach((element) => {
            if (
                [
                    "name",
                    "description",
                    "duration_in_minutes",
                    "admin_id",
                    "session_day",
                    "year",
                    "maximum_attendance",
                    "session_date",
                    "session_url",
                    "course_id",
                    "teacher_id",
                    "session_id",
                ].includes(element)
            ) {
                result[element] = response[element];
            }
        });

        setData({ ...data, ...result });
    };

    useEffect(() => {
        if (data.choosen_admin_id > 0 && data.submit_type && data.submit_type !== "store") {
            setData({ ...data, session_id: 0 });
            getSessions();
        }
    }, [data.choosen_admin_id, data.submit_type, data.choosen_session_day]);

    useEffect(() => {
        if (data.session_id > 0 && data.submit_type && data.submit_type !== "store") {
            getSessionsInfo();
        }
    }, [data.session_id, data.submit_type]);

    useEffect(() => {
        getAdmins();
        getTeachers();
    }, []);

    useEffect(() => {
        if (data.year && data.teacher_id && data.teacher_id > 0) {
            setCourses([]);
            getCourses();
            setData({ ...data, course_id: [] });
        }
    }, [data.year, data.teacher_id]);

    useEffect(() => {
        let fields = [
            {
                id: "submit_type",
                placeholder: "هتعمل ايه دلوقتي",
                type: "select",
                options: submitTypes,
            },
        ];

        if (data.submit_type) {
            if (data.submit_type && data.submit_type !== "store") {
                fields = [
                    ...fields,
                    {
                        id: "choosen_admin_id",
                        placeholder: "اختر المدرس المساعد",
                        type: "select",
                        options: admins,
                    },
                    {
                        id: "choosen_session_day",
                        placeholder: "أختر يوم الجلسة (إختياري)",
                        type: "datepicker",
                        isRequired: false,
                    },
                ];
                if (data.choosen_admin_id > 0) {
                    fields = [
                        ...fields,
                        {
                            id: "session_id",
                            placeholder: "اختر الجلسة",
                            type: "select",
                            options: sessions,
                        },
                    ];
                }
                if (data.submit_type === "update") {
                    if (data.admin_id > 0 && data.session_id > 0) {
                        fields = [
                            ...fields,
                            {
                                id: "name",
                                placeholder: "عنوان الجلسة",
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                            },
                            {
                                id: "description",
                                placeholder: "الوصف",
                                type: "textarea",
                                icon: <InputIcon icon="ant-design:info-circle-twotone" />,
                            },
                            {
                                id: "duration_in_minutes",
                                placeholder: "مدة الجلسة (بالدقائق)",
                                type: "number",
                            },
                            {
                                id: "admin_id",
                                placeholder: "اختر المدرس المساعد",
                                type: "select",
                                options: admins,
                            },
                        ];
                        if (isMultiYear) {
                            fields = [
                                ...fields,
                                {
                                    id: "year",
                                    placeholder: "اختر الصف الدراسي",
                                    type: "select",
                                    options: years,
                                },
                            ];
                        }
                        fields = [
                            ...fields,
                            {
                                id: "teacher_id",
                                placeholder: "اللايف مخصص لمين ؟",
                                type: "select",
                                options: teachers,
                            },
                            {
                                id: "course_id",
                                placeholder: "أختر الكورسات",
                                multiple: true,
                                type: "select",
                                options: courses,
                                icon: <InputIcon icon="fluent:app-title-24-filled" />,
                                isDisabled: !data.teacher_id,
                            },
                            {
                                id: "maximum_attendance",
                                placeholder: "الحد الأقصى للحضور",
                                type: "number",
                            },
                            {
                                id: "session_date",
                                placeholder: "تاريخ الجلسة",
                                type: "datetime",
                            },
                            {
                                id: "session_url",
                                placeholder: "رابط الجلسة (إختياري)",
                                type: "text",
                                isRequired: false,
                            },
                        ];
                    }
                }
            } else if (data.submit_type === "store") {
                fields = [
                    ...fields,
                    {
                        id: "name",
                        placeholder: "عنوان الجلسة",
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                    },
                    {
                        id: "description",
                        placeholder: "الوصف",
                        type: "textarea",
                        icon: <InputIcon icon="ant-design:info-circle-twotone" />,
                    },
                    {
                        id: "admin_id",
                        placeholder: "اختر المدرس المساعد",
                        type: "select",
                        options: admins,
                    },
                ];
                if (isMultiYear) {
                    fields = [
                        ...fields,
                        {
                            id: "year",
                            placeholder: "اختر الصف الدراسي",
                            type: "select",
                            options: years,
                        },
                    ];
                }
                fields = [
                    ...fields,
                    {
                        id: "teacher_id",
                        placeholder: "اللايف مخصص لمين ؟",
                        type: "select",
                        options: teachers,
                    },
                    {
                        id: "course_id",
                        placeholder: "أختر الكورسات",
                        multiple: true,
                        type: "select",
                        options: courses,
                        icon: <InputIcon icon="fluent:app-title-24-filled" />,
                        isDisabled: !data.teacher_id,
                    },
                    {
                        id: "duration_in_minutes",
                        placeholder: "مدة الجلسة (بالدقائق)",
                        type: "number",
                    },
                    {
                        id: "maximum_attendance",
                        placeholder: "الحد الأقصى للحضور",
                        type: "number",
                    },
                    {
                        id: "session_date",
                        placeholder: "تاريخ الجلسة",
                        type: "datetime",
                    },
                    {
                        id: "session_url",
                        placeholder: "رابط الجلسة (إختياري)",
                        type: "text",
                        isRequired: false,
                    },
                ];
            }
        }

        setInputFields(fields);
    }, [
        data.submit_type,
        courses,
        sessions,
        data.course_id,
        data.choosen_admin_id,
        data.choosen_session_day,
        data.teacher_id,
    ]);

    const handleSubmit = (e) => {
        handleFormSubmit(e, setIsLoading, setErrors, () => {
            const adminToken = auth.getAdminToken();
            const config = auth.getAdminAuthConfig(adminToken);
            const crudApiEndPoint = "/api/live_sessions";
            const crudItemName = "الجلسة";

            handleCrud(
                config,
                data,
                crudApiEndPoint,
                crudItemName,
                setIsLoading,
                setErrors,
                setData,
                initialState,
                data.session_id
            );
        });
    };

    return (
        <AdminContainer>
            <AdminForm
                onSubmit={handleSubmit}
                isLoading={isLoading}
                buttonText="حفظ"
                submitType={data.submit_type}
            >
                {inputFields.map((input, key) =>
                    renderInputFields(
                        key,
                        input.handleChange ? input.handleChange : handleChange,
                        data,
                        setData,
                        errors,
                        input
                    )
                )}
            </AdminForm>
        </AdminContainer>
    );
};

export default SessionEditing;
