import React, { useEffect, useState } from "react";
import InputField from "../../components/form/elements/InputField";
import AdminContainer from "../../components/ui/AdminContainer";
import RemoteTable from "../../components/ui/RemoteTable";

import { printFullDateTime } from "../../utils/time";
import { handleInputChange } from "../../services/formServices";
import http from "../../services/httpServices";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";

import { Link } from "react-router-dom";
import SessionRateCard from "../../components/form/SessionRateCard";
import modal from "../../services/modalServices";
import { getOptionSingleText } from "../../utils/objects";
import { sessionAdminsOptionsRate, sessionOptionsRate } from "../../services/contentServices";
import { printIdOfOptions } from "../../utils/ar";

const LiveSessionUserAttendancesTable = ({ showOnlyAdminLiveSessionAttendances = false }) => {
    const [sessionRateData, setSessionRateData] = useState({
        admin_user_rating: "",
        admin_user_comment: "",
        attendance_status: "",
    });

    const [editableData, setEditableData] = useState(null);
    const [isToEditData, setIsToEditData] = useState(false);

    const handleAdminRateSession = async (live_session_user_attendance_id) => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        try {
            const { data: response } = await http.post(
                `api/live_sessions/admin_live_sessions_attendance/${live_session_user_attendance_id}/rate_user`,
                sessionRateData,
                config
            );
            modal.message({
                title: " تم التقييم بنجاح",
                text: response.message,
                callback: () => {
                    const updatedAttendance = response.updated_live_session_user_attendance;

                    let newData = editableData.map((row) => {
                        if (row.id === updatedAttendance.id) {
                            return {
                                ...row,
                                attendance_status: updatedAttendance.attendance_status,
                                admin_user_rating: updatedAttendance.admin_user_rating,
                                admin_user_comment: updatedAttendance.admin_user_comment ?? "",
                                admin_rated_at: updatedAttendance.admin_rated_at,
                            };
                        } else {
                            return row;
                        }
                    });
                    setEditableData(newData);
                    setIsToEditData(true);
                },
            });
        } catch ({ response }) {
            console.log(response);
        }
    };
    let columns = [
        {
            name: "التسلسل",
            reorder: true,
            selector: (row) => row.id,
            sortable: true,
            sortField: "id",
        },
        {
            name: "اسم الطالب",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    row.user.full_name
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
            // sortable: true,
            // sortField: "user.full_name",
        },
        {
            name: "عنوان الجلسة",
            reorder: true,
            selector: (row) => (
                <div>
                    <div className="!whitespace-pre-wrap">{row.live_session.name}</div>
                    <div className="!whitespace-pre-wrap">
                        {printFullDateTime(row.live_session.session_date)}
                    </div>
                    <div className="!whitespace-pre-wrap">
                        {printIdOfOptions(admins, row.live_session.admin_id, "", "")}
                    </div>
                </div>
            ),
            // sortable: true,
            // sortField: "user.full_name",
        },
        {
            name: "رقم هاتف الطالب",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    <Link
                        className="block px-1 py-1 text-center underline"
                        element="Link"
                        to={`../user_profile/${row.user.id}`}
                    >
                        0{row.user.phone}
                    </Link>
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
        },
        {
            name: "رقم هاتف ولى الأمر",
            reorder: true,
            selector: (row) =>
                row.user ? (
                    <>0{row.user.father_phone}</>
                ) : (
                    <span className="text-rose-500">-- لم يتم العثور علي المستخدم --</span>
                ),
        },
    ];
    columns = [
        ...columns,
        {
            name: "حالة حضور الطالب",
            reorder: true,
            selector: (row) => (row.attendance_status ? row.attendance_status : "لا يوجد حضور"),
            sortable: true,
            sortField: "attendance_status",
        },
        {
            name: "تقييم الطالب للمدرس",
            reorder: true,
            selector: (row) =>
                row.live_session_user_rating
                    ? getOptionSingleText(sessionOptionsRate, row.live_session_user_rating)
                    : "لم يتم التقييم",
            sortable: true,
            sortField: "live_session_user_rating",
        },
        {
            name: "ملاحظة الطالب",
            reorder: true,
            selector: (row) =>
                row.live_session_user_comment ? row.live_session_user_comment : "لا توجد ملاحظة",
        },
        {
            name: " وقت تقييم الطالب",
            reorder: true,
            selector: (row) => (row.user_rated_at ? printFullDateTime(row.user_rated_at) : "---"),
            sortable: true,
            sortField: "user_rated_at",
        },
    ];
    columns.push({
        name: "قيم الطالب في الجلسة",
        reorder: true,
        selector: (row) =>
            row.admin_user_rating ? (
                `تم تقييم الطالب, حالة الحضور : ${row.attendance_status}`
            ) : (
                <SessionRateCard
                    data={sessionRateData}
                    setData={setSessionRateData}
                    handleRateSession={handleAdminRateSession}
                    sessionId={row.id}
                    isAdmin
                />
            ),
        minWidth: "400px",
    });
    columns = [
        ...columns,
        {
            name: "تقييم المدرس المساعد للطالب",
            reorder: true,
            selector: (row) =>
                row.admin_user_rating
                    ? getOptionSingleText(sessionAdminsOptionsRate, row.admin_user_rating)
                    : "لم يتم التقييم",
        },
        {
            name: "ملاحظة المدرس المساعد",
            reorder: true,
            selector: (row) => (row.admin_user_comment ? row.admin_user_comment : "لا توجد ملاحظة"),
        },
        {
            name: "وقت تقييم للمدرس المساعد",
            reorder: true,
            selector: (row) => (row.admin_rated_at ? printFullDateTime(row.admin_rated_at) : "---"),
            sortable: true,
            sortField: "admin_rated_at",
        },
    ];

    const initialState = {
        full_name: "",
        phone: "",
        is_admin_rated: 0,
        attendance_status: 0,
        live_session_id: 0,
        admin_id: 0,
        session_day: "",
        show_admin_sessions_only: showOnlyAdminLiveSessionAttendances ? 1 : 0,
    };

    const [filterData, setFilterData] = useState(initialState);

    const [data, setData] = useState(initialState);

    const [sessions, setSessions] = useState([]);
    const [admins, setAdmins] = useState([]);

    const getSessions = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(`/api/live_sessions/options`, {
            params: {
                admin_id: data.admin_id,
                session_day: data.session_day,
            },
            ...config,
        });
        setSessions(response);
    };

    const getAdmins = async () => {
        const token = auth.getAdminToken();
        const config = auth.getAdminAuthConfig(token);
        const { data: response } = await http.get(`/api/admins`, config);

        let adminOptions = response.map((user) => {
            return { value: user.id, text: user.full_name + ` (@${user.email})` };
        });
        setAdmins(adminOptions);
    };

    useEffect(() => {
        !showOnlyAdminLiveSessionAttendances && getAdmins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFilterData(initialState);
        setData(initialState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnlyAdminLiveSessionAttendances]);

    useEffect(() => {
        getSessions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.admin_id, data.session_day]);

    return (
        <AdminContainer>
            <div className="w-full space-y-6">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6 w-full">
                    <InputField
                        id="full_name"
                        placeholder="اسم الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="phone"
                        placeholder="رقم هاتف الطالب"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                    />
                    <InputField
                        id="is_admin_rated"
                        placeholder="حالة تقييم الأدمن"
                        type="select"
                        options={[
                            { value: "all", text: "الكل" },
                            { value: "rated", text: "تم التقييم" },
                            { value: "not_rated", text: "لم يتم التقييم" },
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    <InputField
                        id="attendance_status"
                        placeholder="حالة حضور الطالب"
                        type="select"
                        options={[
                            { value: "all", text: "الكل" },
                            { value: "attended", text: "حضر" },
                            { value: "absent", text: "لم يحضر" },
                            { value: "pending", text: "لم يتم التسجيل من قِبل المشرف" },
                        ]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    {showOnlyAdminLiveSessionAttendances ? (
                        ""
                    ) : (
                        <InputField
                            id="admin_id"
                            placeholder="المسئول"
                            type="select"
                            options={[{ value: 0, text: "الكل" }, ...admins]}
                            onChange={handleInputChange}
                            data={data}
                            setData={setData}
                            className="lg:col-span-2"
                        />
                    )}

                    <InputField
                        id="session_day"
                        placeholder="أختر يوم الجلسة"
                        type="datepicker"
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                    <InputField
                        id="live_session_id"
                        placeholder="الجلسة المراد متابعتها"
                        type="select"
                        options={[{ value: 0, text: "الكل" }, ...sessions]}
                        onChange={handleInputChange}
                        data={data}
                        setData={setData}
                        className="lg:col-span-2"
                    />
                </div>
                <Button
                    className="w-full"
                    color="blue"
                    onClick={() => {
                        setFilterData(data);
                    }}
                >
                    بحث
                </Button>
                <RemoteTable
                    api={`/api/admin_live_sessions_attendance/paginate`}
                    columns={columns}
                    filterData={filterData}
                    isToEditData={isToEditData}
                    setIsToEditData={setIsToEditData}
                    editableData={editableData}
                    setEditableData={setEditableData}
                />
            </div>
        </AdminContainer>
    );
};

export default LiveSessionUserAttendancesTable;
