import React, { useEffect, useState } from "react";
import Button from "../components/ui/Button";
import InputField from "../components/form/elements/InputField";
import auth from "../services/authServices";
import http from "../services/httpServices";
import modal from "../services/modalServices";
import InputIcon from "../components/form/elements/InputIcon";
import { handleInputChange as handleChange } from "../services/formServices";
import inputDateIcon from "../assets/Updated-imagery/input_Date.png";
import MeetingIcon from "../assets/Updated-imagery/meeting_icon.png";
import { printFullDateTimeInHours } from "../utils/time";
import LoadingIcon from "../components/ui/LoadingIcon";
import CenterIcon from "../components/ui/CenterIcon";
import localStorageService from "../services/localStorageService";
import { isLiveSessionsMultiTeachers } from "../services/defaultSettings";

const SessionReservationForm = ({ afterSuccess = () => null, userAvailableSessionsCount = 0 }) => {
    const [data, setData] = useState({
        choosen_reservation_date: "",
        selected_session_id: "",
        teacher_id: 0,
        subject_id: 0,
    });
    const authUser = auth.getUser() || {};

    const CACHE_KEYS = {
        subjects: `subjects_options_${authUser?.email}`,
    };
    const CACHE_DURATION = 3600000;
    const [selectedSession, setSelectedSession] = useState(null);
    const [availableSessions, setAvailableSessions] = useState([]);
    const [isFetchLoading, setIsFetchLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [subjectTeachers, setSubjectTeachers] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const isSessionExpired = (session) => {
        const sessionStartTime = new Date(session?.session_date);
        const sessionEndTime = new Date(
            sessionStartTime.getTime() + (session?.duration_in_minutes + 15) * 60000
        );
        return new Date() > sessionEndTime;
    };

    const handleReservation = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        try {
            const { data: response } = await http.post(
                `/api/live_sessions/${selectedSession}/request_attendance`,
                { ...data },
                config
            );
            modal.message({
                title: "تم حجز مكانك",
                text: ` تم حجز الموعد الخاص بيك في المعاد المحدد برجاءالإنتظار`,
                callback: () => {
                    setData([]);
                    afterSuccess();
                },
            });
            setIsLoading(false);
        } catch ({ response }) {
            setIsLoading(false);
            // if (response.status != 422) {
            //     modal.message({
            //         title: "حدث خطأ",
            //         text: "يرجى الاتصال بالدعم",
            //         icon: "error",
            //     });
            // }
            // setIsDisabled(false);
        }
    };

    const handleSessionClick = (sessionId) => {
        if (selectedSession === sessionId) {
            setSelectedSession(null);
            return;
        }
        setSelectedSession(sessionId);
    };

    const fetchAvailableSessions = async (chosenData) => {
        setIsFetchLoading(true);
        try {
            const token = auth.getToken();
            const config = auth.getAuthConfig(token);
            const { data: response } = await http.get(
                `/api/live_sessions/available_sessions?session_day=${chosenData.choosen_reservation_date}&teacher_id=${chosenData.teacher_id}`,
                config
            );

            setAvailableSessions(response.available_sessions);
            setIsFetchLoading(false);
        } catch (error) {
            setIsFetchLoading(false);
            // setError(" حدث خطأ أثناء جلب المواعيد المتاحة");
        }
    };

    // GET SUBJECTS BASED ON USER YEAR
    const getSubjects = async () => {
        const cachedData = localStorageService.get(CACHE_KEYS?.subjects);
        if (cachedData) {
            setSubjects(cachedData);

            return;
        }
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        const { data: response } = await http.get(
            `/api/years/${authUser?.year}/subjects/options`,
            config
        );
        setSubjects(response);
        localStorageService.set(CACHE_KEYS.subjects, response, CACHE_DURATION);
    };
    // SET MO SALAH ARABIC SUBJECT ID

    // GET TEACHERS BASED ON SUBJECT_ID
    const getSubjectTeacher = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data: response } = await http.get(
            `api/subjects/${data.subject_id}/teachers/option`,
            config
        );
        setSubjectTeachers([]);
        // setSubjectTeachers(response);
        const filteredTeachers = response.filter((teacher) =>
            userAvailableSessionsCount.hasOwnProperty(teacher.value)
        );

        setSubjectTeachers(filteredTeachers);
        setIsLoading(false);
    };
    useEffect(() => {
        const isTeacherValid = isLiveSessionsMultiTeachers
            ? data.teacher_id && data.teacher_id > 0
            : true;

        if (data.choosen_reservation_date && isTeacherValid) {
            setAvailableSessions([]);
            fetchAvailableSessions(data);
        }
    }, [data.choosen_reservation_date, data.teacher_id, isLiveSessionsMultiTeachers]);

    useEffect(() => {
        if (data.teacher_id && data.teacher_id > 0) {
            setData({ ...data, choosen_reservation_date: "", selected_session_id: 0 });
        }
    }, [data.teacher_id]);

    useEffect(() => {
        if (data.subject_id) {
            getSubjectTeacher();
        }
    }, [data.subject_id]);
    useEffect(() => {
        if (isLiveSessionsMultiTeachers) getSubjects();
    }, []);
    return (
        <div className=" flex flex-col space-y-2">
            <div className="flex flex-center-both">
                <div className="flex flex-col space-y-20 w-full">
                    <div>
                        <div className="pb-5 text-center space-y-3">
                            <div className="font-h1">
                                بعد ما تختار اليوم المراد حجزه هتظهر ليك المواعيد المتاحة
                            </div>
                        </div>
                        <div className="flex-center-both flex-col">
                            <div className="flex flex-col items-end space-y-5 pb-4 pt-2 w-full">
                                {isLiveSessionsMultiTeachers && (
                                    <div className="flex space-x-4 bg-primSky-800 dark:bg-primSky-200 smooth p-4 rounded-md shadow-md space-x-reverse w-full">
                                        <InputField
                                            id="subject_id"
                                            placeholder="اختر المادة الي عايز تحجز جلساتها!"
                                            data={data}
                                            setData={setData}
                                            onChange={handleChange}
                                            options={subjects}
                                            type="select"
                                            className="basis-1/2 lg:basis-full"
                                        />
                                        <InputField
                                            id="teacher_id"
                                            placeholder="عايز تحجز لايف مين من المدرسين؟"
                                            data={data}
                                            setData={setData}
                                            onChange={handleChange}
                                            options={subjectTeachers}
                                            type="select"
                                            isDisabled={!data.subject_id}
                                            className="basis-1/2 lg:basis-full"
                                        />
                                    </div>
                                )}
                                <div
                                    className="relative w-full bg-violet-100 dark:bg-gray-900 border border-gray-300 dark:border-gray-700 
                rounded-2xl p-4 shadow-md smooth"
                                >
                                    {" "}
                                    <InputField
                                        id="choosen_reservation_date"
                                        className="w-full clr-text-primary smooth"
                                        placeholder="أختر التاريخ المناسب ليك"
                                        icon={
                                            <span className="flex-center-both mb-1">
                                                <InputIcon icon="icon-park-solid:edit-name" />
                                            </span>
                                        }
                                        data={data}
                                        setData={setData}
                                        onChange={handleChange}
                                        type="datepicker"
                                        datePickerPerWeek
                                        isDisabled={
                                            (!data.teacher_id && isLiveSessionsMultiTeachers) ||
                                            (!isLiveSessionsMultiTeachers
                                                ? userAvailableSessionsCount <= 0
                                                : userAvailableSessionsCount[data.teacher_id]
                                                      ?.live_sessions_allowed <= 0)
                                        }
                                    />
                                    {!isLiveSessionsMultiTeachers ? (
                                        userAvailableSessionsCount > 0 ? (
                                            ""
                                        ) : (
                                            <div className="text-center pt-5">
                                                للأسف انت خلصت رصيدك من حجز الجلسات المباشره
                                            </div>
                                        )
                                    ) : userAvailableSessionsCount[data.teacher_id]
                                          ?.live_sessions_allowed > 0 ? (
                                        ""
                                    ) : (
                                        <div className="text-center pt-5">
                                            للأسف انت خلصت رصيدك من حجز الجلسات المباشره للمدرس ده
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {!data.choosen_reservation_date ? (
                        <div className="w-full flex-center-both  flex-col space-y-5  bg-violet-100 border border-black rounded-3xl py-5 px-20 dark:bg-gray-900 smooth clr-text-primary">
                            <img src={inputDateIcon} alt="inputDateIcon" className="w-20 h-auto" />
                            <span className="w-full font-h2 text-center">
                                أختار اليوم المناسب عشان تشوف المواعيد المُتاحة
                            </span>
                        </div>
                    ) : isFetchLoading ? (
                        <div className="flex-center-both flex-row space-x-3 space-x-reverse bg-third-container clr-text-primary border border-secondary-container smooth py-5 px-5 rounded-md">
                            <div className="font-w-bold font-h1 text-violet-500">
                                <LoadingIcon />
                            </div>
                            <div className="font-w-bold font-h3">
                                يتم الآن تحميل المواعيد المتاحة لليوم المختار
                            </div>
                        </div>
                    ) : availableSessions.length && availableSessions.length > 0 ? (
                        <div className="flex space-y-10 flex-col items-end pb-4 pt-2 w-full">
                            <h1 className="flex-center-both w-full flex-col space-y-5">
                                <img
                                    src={inputDateIcon}
                                    alt="inputDateIcon"
                                    className="w-[56px] h-auto"
                                />
                                <span className="font-h2">الحق أختار الميعاد المناسب ليك</span>
                            </h1>
                            <div className="w-full flex-center-both flex-col space-y-10 p-5">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full gap-4 ">
                                    {availableSessions.map((session) => (
                                        <button
                                            disabled={
                                                session.maximum_attendance <=
                                                session.live_session_user_attendances_count
                                            }
                                            key={session.id}
                                            className={`relative p-3 flex flex-col space-y-4  rounded-md  w-[300px] border-2 ${
                                                session.maximum_attendance <=
                                                session.live_session_user_attendances_count
                                                    ? "cursor-not-allowed opacity-50 border-secondary-container smooth"
                                                    : selectedSession === session.id
                                                    ? "border-secYellow-500 bg-secYellow-950 dark:bg-secYellow-200 smooth"
                                                    : "border-primSky-100/25 bg-primSky-950 dark:bg-primSky-100 smooth hover:bg-primSky-700 dark:hover:bg-primSky-300 "
                                            } focus:outline-none `}
                                            onClick={() => handleSessionClick(session.id)}
                                        >
                                            <h3 className="font-bold clr-text-primary smooth text-center w-full text-lg mb-2 flex-center-both flex-col">
                                                <div className="text-primSky-300 dark:text-primSky-800 smooth font-w-bold">
                                                    معاد الجلسة
                                                </div>{" "}
                                                <div className="font-w-bold bg-sky-500 text-white px-2 rounded-md">
                                                    {printFullDateTimeInHours(session.session_date)}
                                                </div>
                                            </h3>
                                            {/* {session.maximum_attendance ==
                                            session.live_session_user_attendances_count ? (
                                                <div className="flex-center-both w-full">
                                                    <div className="bg-rose-500 text-white px-2 rounded-md pb-1">
                                                        تم امتلاء العدد المسموح
                                                        <br />
                                                        به لهذا المعاد
                                                    </div>
                                                </div>
                                            ) : (
                                                ""
                                            )} */}

                                            <p className="text-sm text-gray-700 dark:text-gray-300 smooth">
                                                <strong className="text-primSky-300 dark:text-primSky-800 smooth">
                                                    عنوان الجلسة المباشره
                                                </strong>{" "}
                                                {session.name}
                                            </p>
                                            <p className="text-sm text-gray-700 dark:text-gray-300 smooth">
                                                <strong className="text-primSky-300 dark:text-primSky-800 smooth">
                                                    المدرس المٌساعد:
                                                </strong>{" "}
                                                {session.admin?.full_name}
                                            </p>
                                            <p className="text-sm text-gray-700 dark:text-gray-300 smooth">
                                                <strong className="text-primSky-300 dark:text-primSky-800 smooth">
                                                    ملحوظة:
                                                </strong>{" "}
                                                {session.description}
                                            </p>
                                            <p className="text-sm flex-center-both gap-2 text-gray-700 dark:text-gray-300 smooth">
                                                <strong className="text-primSky-300 dark:text-primSky-800 smooth">
                                                    الأماكن المتبقية:
                                                </strong>{" "}
                                                {session.maximum_attendance <=
                                                session.live_session_user_attendances_count ? (
                                                    <p className="bg-rose-600 clr-white py-1 px-3 rounded-md">
                                                        للأسف لا يوجد أماكن متبقية
                                                    </p>
                                                ) : (
                                                    <p className="bg-yellow-500 text-black rounded-md py-1 px-3">
                                                        {session.maximum_attendance -
                                                            session.live_session_user_attendances_count}{" "}
                                                        مكان متبقي
                                                    </p>
                                                )}
                                            </p>
                                            <div
                                                className={`absolute top-2 right-2 !m-0 font-h1 text-yellow-500 ${
                                                    selectedSession === session.id
                                                        ? "opacity-100"
                                                        : "opacity-0"
                                                }`}
                                            >
                                                <CenterIcon icon="icon-park-twotone:check-one" />
                                            </div>
                                            {/* <a
                                                href={session.session_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="bg-primSky-500 smooth rounded-xl mx-auto dark:bg-secYellow-800 text-xl text-black py-2 px-4"
                                            >
                                                لينك الجلسة
                                            </a> */}
                                        </button>
                                    ))}
                                </div>
                                {/* Reserve button */}
                                <Button
                                    color="NewYello"
                                    className={`text-xl font-bold text-black px-[20px]  py-[10px] border-none inline-block smooth shadow-md hover:shadow-lg rounded-xl w-3/4 ${
                                        !selectedSession && "opacity-40 cursor-not-allowed"
                                    } `}
                                    isLoading={isLoading}
                                    isDisabled={
                                        !selectedSession ||
                                        isSessionExpired(
                                            availableSessions.find((s) => s.id === selectedSession)
                                        )
                                    }
                                    onClick={() => handleReservation()}
                                >
                                    أحجزلي معاد الجلسة
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex-center-both  flex-col space-y-5  bg-rose-300 border border-black rounded-3xl py-5 px-20 dark:bg-rose-900 smooth ">
                            <img src={MeetingIcon} alt="inputDateIcon" className="w-20 h-auto" />
                            <span className="w-3/4 font-h2 text-center">
                                مفيش مواعيد مُتاحة لليوم ده للأسف .. أختار يوم تاني مناسب ليك
                                ومتيأسش{" "}
                            </span>
                        </div>
                    )}

                    {/* {availableSessions.length < 1 && data.choosen_reservation_date && (
                        
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default SessionReservationForm;
