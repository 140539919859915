import React from "react";
import Heading from "../components/NewUi/heading/Heading";
import AppointmentIcon from "../assets/Updated-imagery/appointment_icon.png";
import moment from "moment";
import Button from "../components/ui/Button";
import { printFullDateTime } from "../utils/time";
import { printRelativeDate } from "../utils/ar";
import { Icon } from "@iconify/react/dist/iconify.js";
import dottedPatternLiveCard from "../assets/dottedPatternLiveCard.svg";
import { isLiveSessionsMultiTeachers } from "../services/defaultSettings";
import { motion } from "framer-motion";
const UpcomingLiveSessions = ({ UpcomingLiveSessions = [], isHomeSection = true }) => {
    return (
        <div className={`flex-col flex-center-both space-y-5`}>
            <div
                className="relative flex flex-col items-center space-y-10 bg-gray-100 smooth dark:bg-gray-900 
                        border border-gray-300 dark:border-gray-700 rounded-3xl p-8 shadow-lg 
                        overflow-hidden"
            >
                <motion.div
                    className="relative flex items-center justify-center w-full"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                >
                    <motion.div
                        className="absolute left-0 h-1 w-16 bg-blue-400 rounded-full"
                        animate={{ x: [0, 10, -10, 0] }}
                        transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                    />
                    <motion.div
                        className="absolute right-0 h-1 w-16 bg-blue-400 rounded-full"
                        animate={{ x: [0, -10, 10, 0] }}
                        transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
                    />
                    <h2
                        className="text-violet-600 text-center md:text-right dark:text-violet-200 smooth text-4xl font-extrabold px-10 py-4 
                               bg-black bg-opacity-10 dark:bg-opacity-20 rounded-xl shadow-lg"
                    >
                        تابع جلساتك المحجوزة{" "}
                    </h2>
                </motion.div>
                {isHomeSection ? (
                    ""
                ) : (
                    <div>
                        لازم تخلي بالك من الجلسات اللي محجوزه عندك علشان تقدر تستفيد منها وتحضرها في
                        موعدها المحدد وتستفيد منها بشكل كامل
                    </div>
                )}
                <div className="font-w-bold">
                    خلي بالك لو محضرتش الجلسة المحجوزة في موعدها المحدد هتتحسب عليك كغياب و مش هتقدر
                    تعوضها !
                </div>
            </div>
            {UpcomingLiveSessions && UpcomingLiveSessions.length > 0 ? (
                <div className="w-full flex-center-both flex-col">
                    <div
                        className={`grid  ${
                            isLiveSessionsMultiTeachers
                                ? "grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
                                : "grid-cols-1"
                        } w-full gap-4 `}
                    >
                        {UpcomingLiveSessions.map((liveAttendance) => (
                            <>
                                <div
                                    className="relative flex mx-2 sm:mx-20 flex-col space-y-5 bg-yellow-50 dark:bg-gray-900 border border-gray-300 dark:border-gray-700 
                   rounded-3xl p-6 shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105"
                                    key={liveAttendance.live_session.id}
                                >
                                    <div
                                        className="absolute inset-0 bg-contain bg-center opacity-10"
                                        style={{ backgroundImage: `url(${dottedPatternLiveCard})` }}
                                    ></div>

                                    <div className="w-full flex flex-col space-y-3 relative z-10">
                                        <h3 className="font-bold text-center w-full text-lg mb-2 flex flex-col items-center">
                                            <Icon
                                                icon="mdi:calendar-clock"
                                                className="text-yellow-700 dark:text-yellow-300 smooth text-3xl mb-1"
                                            />
                                            <span className="text-yellow-700 dark:text-yellow-300 smooth font-extrabold">
                                                معاد الجلسة
                                            </span>
                                            <div className="bg-yellow-500/50 text-black smooth px-4 py-2 rounded-lg shadow-md text-center">
                                                <span className="font-bold">
                                                    {printRelativeDate(
                                                        liveAttendance.live_session.session_date
                                                    )}
                                                </span>
                                                <br />
                                                <span className="text-sm">
                                                    {printFullDateTime(
                                                        liveAttendance.live_session.session_date
                                                    )}
                                                </span>
                                            </div>
                                        </h3>

                                        <p className="text-sm text-gray-700 dark:text-gray-300 smooth flex items-center gap-2">
                                            <Icon
                                                icon="mdi:book-open-variant"
                                                className="text-yellow-500 dark:text-yellow-300 smooth text-lg"
                                            />
                                            <strong className="text-yellow-500 smooth dark:text-yellow-300">
                                                عنوان الجلسة:
                                            </strong>{" "}
                                            {liveAttendance.live_session.name}
                                        </p>
                                        <p className="text-sm text-gray-700 dark:text-gray-300 smooth flex items-center gap-2">
                                            <Icon
                                                icon="mdi:account-tie"
                                                className="text-yellow-500 dark:text-yellow-300 text-lg"
                                            />
                                            <strong className="text-yellow-500 smooth dark:text-yellow-300">
                                                المدرس المٌساعد:
                                            </strong>{" "}
                                            {liveAttendance.live_session.admin?.full_name}
                                        </p>
                                        {liveAttendance.live_session.description && (
                                            <p className="text-sm text-gray-700 dark:text-gray-300 smooth flex items-center gap-2">
                                                <Icon
                                                    icon="mdi:comment-text-outline"
                                                    className="text-yellow-500 smooth dark:text-yellow-300 text-lg"
                                                />
                                                <strong className="text-yellow-500 smooth dark:text-yellow-300">
                                                    ملحوظة:
                                                </strong>{" "}
                                                {liveAttendance.live_session.description}
                                            </p>
                                        )}

                                        {/* Session Join Button */}
                                        {moment(liveAttendance.live_session.session_date).isSame(
                                            moment(),
                                            "day"
                                        ) &&
                                            liveAttendance.live_session.session_url &&
                                            (() => {
                                                const isSessionExpired = moment().isAfter(
                                                    moment(
                                                        liveAttendance.live_session.session_date
                                                    ).add(
                                                        liveAttendance.live_session
                                                            .duration_in_minutes,
                                                        "minutes"
                                                    )
                                                );
                                                return (
                                                    <a
                                                        href={
                                                            liveAttendance.live_session.session_url
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className={`text-center font-bold flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-all duration-300
                                        ${
                                            isSessionExpired
                                                ? "bg-gray-400 cursor-not-allowed"
                                                : "bg-yellow-500 hover:bg-yellow-600 shadow-md text-white"
                                        }`}
                                                        onClick={(e) =>
                                                            isSessionExpired && e.preventDefault()
                                                        }
                                                    >
                                                        <Icon
                                                            icon={
                                                                isSessionExpired
                                                                    ? "mdi:close-circle-outline"
                                                                    : "mdi:video"
                                                            }
                                                            className="text-lg"
                                                        />
                                                        {isSessionExpired
                                                            ? "انتهت الجلسة"
                                                            : "انضم الآن"}
                                                    </a>
                                                );
                                            })()}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="w-full flex-center-both  flex-col space-y-5  bg-[#FFF7CC] border border-black rounded-3xl py-5 px-20 dark:bg-secYellow-200 smooth ">
                    <img src={AppointmentIcon} alt="inputDateIcon" className="w-20 h-auto" />
                    <span className="w-3/4 font-h2 text-center">
                        مفيش ليك مواعيد جلسات محجوزة حاليا
                    </span>
                </div>
            )}
        </div>
    );
};

export default UpcomingLiveSessions;
