import { Icon } from "@iconify/react/dist/iconify.js";
import InputField from "./elements/InputField";
import { sessionAdminsOptionsRate, sessionOptionsRate } from "../../services/contentServices";
import { handleInputChange } from "../../services/formServices";
import Button from "../ui/Button";

const SessionRateCard = ({ data, setData, handleRateSession, sessionId, isAdmin = false }) => {
    return (
        <div className="flex flex-col space-y-4">
            {isAdmin && (
                <InputField
                    id="attendance_status"
                    className="w-full"
                    placeholder="هل الطالب حضر؟"
                    icon={
                        <span className="flex-center-both mb-1">
                            <Icon icon="material-symbols:qr-code" />
                        </span>
                    }
                    data={data}
                    setData={setData}
                    options={[
                        { value: "attended", text: "حضر الجلسة" },
                        { value: "absent", text: "غاب عن الجلسة" },
                    ]}
                    onChange={handleInputChange}
                    type="select"
                    isRequired
                />
            )}
            <InputField
                id={!isAdmin ? "live_session_user_rating" : "admin_user_rating"}
                className="w-full"
                placeholder={!isAdmin ? "تقييمك للجلسة" : "تقييمك للطالب"}
                icon={
                    <span className="flex-center-both mb-1">
                        <Icon icon="material-symbols:qr-code" />
                    </span>
                }
                data={data}
                setData={setData}
                options={isAdmin ? sessionAdminsOptionsRate : sessionOptionsRate}
                onChange={handleInputChange}
                type="select"
                isRequired
            />
            <InputField
                id={!isAdmin ? "live_session_user_comment" : "admin_user_comment"}
                className="w-full smooth text-sm flex pt-3"
                placeholder="تعليقك للجلسة.. (اختياري)"
                data={data}
                setData={setData}
                onChange={handleInputChange}
                type="textarea"
                // isRequired={false}
            />
            <Button onClick={() => handleRateSession(sessionId)} color="slate">
                قيم الجلسة الآن
            </Button>
        </div>
    );
};

export default SessionRateCard;
