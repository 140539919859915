import React, { useState } from "react";
import { printFullDateTime } from "../utils/time";
import { description } from "../utils/ar";
import { getOptionSingleText } from "../utils/objects";
import { sessionOptionsRate } from "../services/contentServices";
import SessionRateCard from "../components/form/SessionRateCard";
import auth from "../services/authServices";
import http from "../services/httpServices";
import modal from "../services/modalServices";
import dottedPatternLiveCard from "../assets/dottedPatternLiveCard.svg";
import { Icon } from "@iconify/react/dist/iconify.js";
import { liveSessionCardColor } from "../services/defaultSettings";

const colorThemes = {
    yellow: {
        bg: "bg-yellow-100 dark:bg-yellow-900",
        title: "text-yellow-600 dark:text-yellow-300",
        text: "text-gray-800 dark:text-gray-300",
        accent: "bg-yellow-600 text-white",
    },
    teal: {
        bg: "bg-teal-100 dark:bg-teal-900",
        title: "text-teal-600 dark:text-teal-300",
        text: "text-gray-800 dark:text-gray-300",
        accent: "bg-teal-600 text-white",
    },
    cyan: {
        bg: "bg-cyan-100 dark:bg-cyan-900",
        title: "text-cyan-600 dark:text-cyan-300",
        text: "text-gray-800 dark:text-gray-300",
        accent: "bg-cyan-600 text-white",
    },
    violet: {
        bg: "bg-violet-100 dark:bg-violet-900",
        title: "text-violet-600 dark:text-violet-300",
        text: "text-gray-800 dark:text-gray-300",
        accent: "bg-violet-600 text-white",
    },
    rose: {
        bg: "bg-rose-100 dark:bg-rose-900",
        title: "text-rose-600 dark:text-rose-300",
        text: "text-gray-800 dark:text-gray-300",
        accent: "bg-rose-600 text-white",
    },
    slate: {
        bg: "bg-slate-100 dark:bg-slate-900",
        title: "text-slate-600 dark:text-slate-300",
        text: "text-gray-800 dark:text-gray-300",
        accent: "bg-slate-600 text-white",
    },
    default: {
        bg: "bg-gray-200 dark:bg-gray-800",
        title: "text-gray-600 dark:text-gray-300",
        text: "text-gray-800 dark:text-gray-300",
        accent: "bg-gray-600 text-white",
    },
};

const LiveSessionUserAttendanceCard = ({
    session = {},
    handleAfterSuccess = () => null,
    layout = "default",
}) => {
    const [data, setData] = useState({
        live_session_user_rating: "",
        live_session_user_comment: "",
    });
    const [liveSessionColor, setLiveSessionColor] = useState(liveSessionCardColor);
    const selectedTheme = colorThemes[liveSessionColor] || colorThemes.default;

    const handleRateSession = async (live_session_user_attendance_id) => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        try {
            const { data: response } = await http.post(
                `/api/live_sessions/live_session_user_attendances/${live_session_user_attendance_id}/rate_session`,
                data,
                config
            );
            modal.message({
                title: " تم التقييم بنجاح",
                text: response.message,
                callback: () => {
                    handleAfterSuccess();
                },
            });
        } catch ({ response }) {
            // console.log(response);
        }
    };
    if (layout === "default") {
        return (
            <div
                className="bg-secYellow-900 px-4 space-y-10 flex items-center flex-col max-w-[350px] py-5 justify-evenly min-h-[350px] dark:bg-secYellow-200 smooth rounded-xl border-[.5px] border-black"
                key={session.id}
            >
                <div className="w-3/4 flex clr-text-primary smooth flex-col gap-2 ">
                    <h3 className="font-bold  text-center w-full text-lg mb-2 flex-center-both flex-col">
                        <div className="text-primSky-300 dark:text-primSky-800 smooth">
                            كان معاد الجلسة
                        </div>
                        <div>{printFullDateTime(session.live_session.session_date)}</div>
                    </h3>
                    <p>
                        <label>عنوان الجلسة : </label>
                        {session.live_session.name}
                    </p>
                    <p>
                        <label>تفاصيل الجلسة : </label>
                        {description(session.live_session.description, true)}
                    </p>
                    {session.attendance_status === "attended" &&
                    session.live_session_user_rating !== null ? (
                        <div className="py-3 px-2 flex-center-both flex-col space-y-5 bg-primSky-800 dark:bg-primSky-200 smooth clr-text-primary rounded-md border-[.5px] shadow-md border-black">
                            <div className="flex-center-both flex-col space-y-3">
                                <div className="flex gap-2 w-full">
                                    <label>حالة الحضور: </label>
                                    {session.attendance_status === "pending" ? (
                                        <span className="text-yellow-500 w-1/2 text-center ">
                                            لم يتم تحديد حالة حضورك بعد
                                        </span>
                                    ) : session.attendance_status === "attended" ? (
                                        <span className="text-green-600">حضرت الجلسة</span>
                                    ) : (
                                        <span className="text-red-500">كنت غايب عن الجلسة</span>
                                    )}
                                </div>
                                <div className="w-full">
                                    {
                                        <p className="flex w-full gap-2">
                                            <label className="text-rose-500 font-semibold text-sm">
                                                تقيمك للمدرس:{" "}
                                            </label>
                                            {getOptionSingleText(
                                                sessionOptionsRate,
                                                session.live_session_user_rating
                                            )}
                                        </p>
                                    }
                                </div>

                                {session.attendance_status === "attended" &&
                                    session.admin_user_rating !== null && (
                                        <p className="flex flex-col w-full gap-2">
                                            <p className="flex w-full gap-2">
                                                <label className="text-rose-500 font-semibold text-sm">
                                                    المدرس المٌساعد:{" "}
                                                </label>
                                                <span className="text-sm">
                                                    {session.live_session.admin.full_name}
                                                </span>
                                            </p>
                                            <p className="flex gap-2 w-full">
                                                <label className="text-rose-500 font-semibold text-sm ">
                                                    تقيم المدرس ليك:{" "}
                                                </label>
                                                <span className="text-sm">
                                                    {getOptionSingleText(
                                                        sessionOptionsRate,
                                                        session.admin_user_rating
                                                    )}
                                                </span>
                                            </p>
                                            {session.admin_rated_at !== null && (
                                                <p className="flex items-center gap-1 w-full ">
                                                    <label className="text-rose-500 font-semibold text-sm">
                                                        تاريخ التقييم :{" "}
                                                    </label>
                                                    <span className="w-1/2 text-center text-sm">
                                                        {printFullDateTime(session.admin_rated_at)}
                                                    </span>
                                                </p>
                                            )}

                                            {session.admin_user_comment !== null && (
                                                <>
                                                    <label className="text-rose-500 font-semibold text-sm">
                                                        ملاحظة المدرس:{" "}
                                                    </label>
                                                    {
                                                        <span className="text-sm">
                                                            {session.admin_user_comment}
                                                        </span>
                                                    }
                                                </>
                                            )}
                                        </p>
                                    )}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {session.attendance_status === "attended" ? (
                    <SessionRateCard
                        data={data}
                        setData={setData}
                        handleRateSession={handleRateSession}
                        sessionId={session.id}
                    />
                ) : session.attendance_status === "pending" ? (
                    <p className="font-bold text-center">
                        في انتظار تسجيل بياناتك من قبل المشرف عشان تقدر تقيم الجلسة
                    </p>
                ) : (
                    <p className="font-bold text-center">
                        للأسف انت غيبت في الجلسة دي , مش هتقدر تقيمها
                    </p>
                )}
            </div>
        );
    } else if (layout === "detailed") {
        return (
            <div
                className={`relative flex smooth flex-col space-y-5 ${selectedTheme.bg} border border-gray-300 dark:border-gray-700 
                        rounded-3xl p-6 shadow-lg overflow-hidden transition-all duration-300 transform hover:scale-105`}
                key={session.id}
            >
                {/* Blurred Dotted Background */}
                <div
                    className="absolute inset-0 bg-contain bg-center opacity-20"
                    style={{ backgroundImage: `url(${dottedPatternLiveCard})` }}
                ></div>

                <div className="w-full flex flex-col space-y-3 relative z-10">
                    {/* Session Date */}
                    <h3
                        className={`font-bold ${selectedTheme.title} smooth text-center w-full text-lg mb-2 flex flex-col items-center`}
                    >
                        <Icon
                            icon="mdi:calendar-clock"
                            className={`${selectedTheme.title} smooth text-3xl mb-1`}
                        />
                        <span className="font-extrabold">كان معاد الجلسة</span>
                        <div
                            className={`${selectedTheme.accent} smooth px-4 py-2 rounded-lg shadow-md text-center`}
                        >
                            {printFullDateTime(session.live_session.session_date)}
                        </div>
                    </h3>

                    {/* Session Info */}
                    <p className={`text-sm ${selectedTheme.text} smooth flex items-center gap-2`}>
                        <Icon
                            icon="mdi:book-open-variant"
                            className={`${selectedTheme.title} smooth text-lg`}
                        />
                        <strong className={`${selectedTheme.title} smooth`}>عنوان الجلسة:</strong>{" "}
                        {session.live_session.name}
                    </p>
                    <p className={`text-sm ${selectedTheme.text} smooth flex items-center gap-2`}>
                        <Icon
                            icon="mdi:file-document-edit"
                            className={`${selectedTheme.title} smooth text-lg`}
                        />
                        <strong className={`${selectedTheme.title} smooth`}>تفاصيل الجلسة:</strong>{" "}
                        {description(session.live_session.description, true)}
                    </p>

                    {/* Attendance Status & Rating */}
                    {session.attendance_status === "attended" &&
                    session.live_session_user_rating !== null ? (
                        <div
                            className={`py-3 px-2 flex-center-both flex-col smooth space-y-5 ${selectedTheme.accent} text-white dark:text-gray-900 rounded-md border-[.5px] shadow-md border-black`}
                        >
                            <div className="flex-center-both flex-col space-y-3">
                                {/* Attendance Status */}
                                <div className="flex gap-2 w-full">
                                    <Icon
                                        icon="mdi:account-check"
                                        className="text-green-500 text-lg"
                                    />
                                    <label>حالة الحضور:</label>
                                    {session.attendance_status === "pending" ? (
                                        <span className="text-yellow-300 w-1/2 text-center">
                                            لم يتم تحديد حالة حضورك بعد
                                        </span>
                                    ) : session.attendance_status === "attended" ? (
                                        <span className="text-green-500">حضرت الجلسة</span>
                                    ) : (
                                        <span className="text-red-500">كنت غايب عن الجلسة</span>
                                    )}
                                </div>

                                {/* Student Rating */}
                                <div className="w-full">
                                    <p className="flex w-full gap-2">
                                        <Icon icon="mdi:star" className="text-yellow-400 text-lg" />
                                        <label className="text-red-500 font-semibold text-sm">
                                            تقييمك للمدرس:
                                        </label>
                                        {getOptionSingleText(
                                            sessionOptionsRate,
                                            session.live_session_user_rating
                                        )}
                                    </p>
                                </div>

                                {/* Teacher Feedback */}
                                {session.attendance_status === "attended" &&
                                    session.admin_user_rating !== null && (
                                        <div className="w-full">
                                            <p className="flex w-full gap-2">
                                                <Icon
                                                    icon="mdi:account-tie"
                                                    className="text-red-500 text-lg"
                                                />
                                                <label className="text-red-500 font-semibold text-sm">
                                                    المدرس المٌساعد:
                                                </label>
                                                <span className="text-sm">
                                                    {session.live_session.admin.full_name}
                                                </span>
                                            </p>
                                            <p className="flex gap-2 w-full">
                                                <Icon
                                                    icon="mdi:thumb-up-outline"
                                                    className="text-red-500 text-lg"
                                                />
                                                <label className="text-red-500 font-semibold text-sm">
                                                    تقييم المدرس ليك:
                                                </label>
                                                <span className="text-sm">
                                                    {getOptionSingleText(
                                                        sessionOptionsRate,
                                                        session.admin_user_rating
                                                    )}
                                                </span>
                                            </p>
                                            {session.admin_rated_at !== null && (
                                                <p className="flex items-center gap-1 w-full">
                                                    <Icon
                                                        icon="mdi:calendar-check"
                                                        className="text-red-500 text-lg"
                                                    />
                                                    <label className="text-red-500 font-semibold text-sm">
                                                        تاريخ التقييم:
                                                    </label>
                                                    <span className="w-1/2 text-center text-sm">
                                                        {printFullDateTime(session.admin_rated_at)}
                                                    </span>
                                                </p>
                                            )}
                                            {session.admin_user_comment !== null && (
                                                <p className="w-full">
                                                    <Icon
                                                        icon="mdi:message-text-outline"
                                                        className="text-red-500 text-lg"
                                                    />
                                                    <label className="text-red-500 font-semibold text-sm">
                                                        ملاحظة المدرس:
                                                    </label>
                                                    <span className="text-sm">
                                                        {session.admin_user_comment}
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>

                {/* Rating Section */}
                {session.attendance_status === "attended" ? (
                    <SessionRateCard
                        data={data}
                        setData={setData}
                        handleRateSession={handleRateSession}
                        sessionId={session.id}
                    />
                ) : session.attendance_status === "pending" ? (
                    <p className="font-bold w-3/4 mx-auto pt-4 opacity-50 gap-5 flex-center-both  text-sm clr-text-primary smooth">
                        <Icon
                            icon="mdi:clock-alert-outline"
                            className="text-yellow-500 text-lg w-10 h-10"
                        />{" "}
                        <span className="">
                            في انتظار تسجيل بياناتك من قبل المشرف عشان تقدر تقيم الجلسة
                        </span>
                    </p>
                ) : (
                    <p className="font-bold text-center">
                        <Icon icon="mdi:cancel" className="text-red-500 text-lg" /> للأسف انت غيبت
                        في الجلسة دي، مش هتقدر تقيمها
                    </p>
                )}
            </div>
        );
    }
};

export default LiveSessionUserAttendanceCard;
